import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AstroDetailsTable from './AstroloDetails';
const AstrolDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Astrolloger Details' parent='Astrolloger Details'  />
      <Container fluid={true}>
        <Row>
          {/* <AstroDetailsTable /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AstrolDetails;
