// dashbaord
import Social from "../Components/Dashboard/Social";
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import Answer from "../Pages/Answers";
import AstrolDetails from "../Pages/AstroloDetails";
import Astrologers from "../Pages/Astrologers";
import Category from "../Pages/Category";
import Exam from "../Pages/Exam";
import Language from "../Pages/Language";
import Products from "../Pages/Products";
import ProductImages from "../Pages/Products/ProductImages/ProductImages";
import Question from "../Pages/Questions";
import Specialization from "../Pages/Specialization";
import Stream from "../Pages/Stream";
import SubCategory from "../Pages/SubCategory";


export const routes = [
  { path: `/dashboard/:layout`, Component: <Social /> },
  { path: `/astrologers/:layout`, Component: <Astrologers /> },
  { path: `/astrologers/astroDetails/:layout`, Component: <AstrolDetails /> },
  { path: `/specialization/:layout`, Component: <Specialization /> },
  { path: `/category/:layout`, Component: <Category /> },
  { path: `/subcat/:layout`, Component: <SubCategory /> },
  { path: `/products/:layout`, Component: <Products /> },
  { path: `/productImages/:layout`, Component: <ProductImages /> },
  
  { path: `/exams/:layout`, Component: <Exam /> },
  { path: `/exams/question/:layout`, Component: <Question /> },
  { path: `/exams/question/answer/:layout`, Component: <Answer /> },


  { path: `/stream/:layout`, Component: <Stream /> },
  { path: `/language/:layout`, Component: <Language /> },


  //Error
  { path: `*`, Component: <ErrorPage4 /> },

];
