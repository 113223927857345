import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import Empty from '../../Components/Empty/Index';
import moment from 'moment';
import { getCategory, ModalToggle, isOpenModal,  updateCategory, addCategory, statusUpdateCategory } from '../../store/categorySlice';
import { getastrologer, isOpenImageViewModal, isOpenStatusModal, statusToggle, statusUpdateastrologer } from '../../store/astrologerSlice';
import { useNavigate } from 'react-router';

const AstroDetailsTable = () => {
  const storeVar = useSelector(state => state.astrologer)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const [stateStatus, setStateStatus] = useState('active');
  const [submit, setSubmit] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'active',
    modalTitle: null,
    editState: false,
    categoryId: '',
    name: '',
  });

  useEffect(() => {
    dispatch(getastrologer(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
  }, []);



  const imageViewToggleModal = (image) => {
    if (image) {
      dispatch(isOpenImageViewModal(true))
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        viewThumbnailURL: image,
      }))
    }
  }
  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getastrologer(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getastrologer(formVar.limit, formVar.offset, formVar.status, keyword))
    }, typingDelay);
    setTypingTimer(timer);
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getastrologer(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      categoryId: data.id,
      modalTitle: 'Edit Category',
      name: data.name,
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Category',
      name: '',
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      categoryId: data.id,
    }))
  }

  const submitMenu = () => {
    if (categoryNameValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updateCategory(formVar.categoryId, { name: formVar.name }))
    } else {
      dispatch(addCategory({ name: formVar.name }))
    }
  }
  const submitStatus = () => {
    dispatch(statusUpdateastrologer({ id: formVar.categoryId, status: stateStatus }))
  }
  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('MMM Do YYYY')
    }
  }

  const categoryNameValid = () => {
    if (!formVar.name) {
      return "Name is required";
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='active'>ACTIVE</option>
                  <option value='pending'>PENDING</option>
                </Input>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Phone Number</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Wallet</th>
                  <th scope='col'>Aadhar Card</th>
                  <th scope='col'>Pan Card</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.astrologerData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>{item.wallet}</td>
                    <td>
                      <div className="w-6-r cursor-pointer" onClick={() => imageViewToggleModal(item.aadharImage)}>
                        <img className='w-100 h-3-r' src={item.aadharImage} alt="img" />
                      </div>
                    </td>
                    <td>
                      <div className="w-6-r cursor-pointer" onClick={() => imageViewToggleModal(item.pancardImage)}>
                        <img className='w-100 h-3-r' src={item.pancardImage} alt="img" />
                      </div>
                    </td>
                    <td>
                      {
                        item.status === 'active' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'active' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'pending' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'pending' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase() }</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-info-circle fa-lg" onClick={(e) => navigate('/astrologers/astroDetails?id='+item.id)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        {/* <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div> */}
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.totalastrologer <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.totalastrologer > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalastrologer}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Name</Label>
            <Input className="form-control" type="text" placeholder='Enter Category Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
            {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitMenu }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'active'} onChange={onValueChange} value='active' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'pending'} onChange={onValueChange} value='pending' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default AstroDetailsTable;
