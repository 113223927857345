import axios from "axios";
import { authHeader } from "../_helper/auth-header";



// const rootUrl = 'http://localhost:7000/api/v1/'
const rootUrl = 'https://server.astrrovidhaan.in/api/v1/'

const authURL = rootUrl + 'auth';
const dashboardURL = rootUrl + 'dashboard';
const astrologerURL = rootUrl + 'auth/astrologers';
const specializationURL = rootUrl + 'specialization';
const categoryURL = rootUrl + "category"
const subCategoryURL = rootUrl + "subCategory"
const productsURL = rootUrl + 'products';
const examURL = rootUrl + 'exam';
const questionsURL = rootUrl + 'question';
const answersURL = rootUrl + 'answers';



const streamURL = rootUrl + "stream"
const languageURL = rootUrl + "language"

/*********** Auth ***********/
async function login(loginData) {
  return await axios.post(authURL + '/admin/login', loginData);
}
async function getDashboard() {
  return await axios.get(dashboardURL, { headers: await authHeader() });
}



/*********** Astrologer ***********/

async function getastrologer(limit, offset, status, keyword) {
  return await axios.get(astrologerURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader()
  });
}

async function statusUpdateastrologer(id, status) {
  return await axios.put(astrologerURL + '/' + id, { status }, {
    headers: await authHeader()
  });
}

/*********** Specialization ***********/

async function getSpecialization() {
  return await axios.get(specializationURL, {
    headers: await authHeader()
  });
}

async function addspecialization(data) {
  return await axios.post(specializationURL, data, {
    headers: await authHeader()
  });
}

async function updateSpecialization(id, data) {
  return await axios.patch(specializationURL + '/' + id, data, {
    headers: await authHeader()
  });
}
async function deleteSpecialization(id) {
  return await axios.delete(specializationURL + '/' + id, {
    headers: await authHeader()
  });
}

/********* CATEGORY *********/
async function getCategory(limit, offset, status, keyword) {
  return await axios.get(categoryURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}
async function addCategory(payload) {
  return await axios.post(categoryURL, payload, {
    headers: await authHeader(),
  });
}
async function updateCategory(id, payload) {
  return await axios.patch(categoryURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateCategory(id, status) {
  return await axios.put(categoryURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}



/********* SUBCATEGORY *********/
async function getsubCategory(limit, offset, status, keyword) {
  return await axios.get(subCategoryURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}

async function addSubCategory(payload) {
  return await axios.post(subCategoryURL, payload, {
    headers: await authHeader(),
  });
}
async function updatesubCategory(id, payload) {
  return await axios.patch(subCategoryURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdatesubCategory(id, status) {
  return await axios.put(subCategoryURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}


/********* PRODUCTS *********/
async function getproducts(limit, offset, status, keyword) {
  return await axios.get(productsURL + '/admin?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}

async function UpdateStatusProducts(data) {
  return await axios.put(productsURL + '/status', data, {
    headers: await authHeader(),
  });
}


/********* EXAMS *********/

async function fetchExam() {
  return await axios.get(examURL, {
    headers: await authHeader(),
  });
}
async function examAdd(data) {
  return await axios.post(examURL, data, {
    headers: await authHeader(),
  });
}
async function examUpdate(id, data) {
  return await axios.patch(examURL + '/' + id, data, {
    headers: await authHeader(),
  });
}
async function examDelete(id) {
  return await axios.delete(examURL + '/' + id, {
    headers: await authHeader(),
  });
}

/********* Questions *********/

async function fetchQuestions(examId) {
  return await axios.get(questionsURL + '/' + examId, {
    headers: await authHeader(),
  });
}

async function addquestion(data) {
  return await axios.post(questionsURL , data, {
    headers: await authHeader(),
  });
}


/********* Questions *********/

async function fetchanswers(examId) {
  return await axios.get(answersURL + '/' + examId, {
    headers: await authHeader(),
  });
}
async function addanswer(id, data) {
  return await axios.post(answersURL + '/' + id, data, {
    headers: await authHeader(),
  });
}
async function updateanswerStatusData(examId) {
  return await axios.delete(answersURL + '/' + examId, {
    headers: await authHeader(),
  });
}





/********* STREAM *********/
async function getStream(limit, offset, status, keyword) {
  return await axios.get(streamURL + '/admin?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}
async function addStream(payload) {
  return await axios.post(streamURL, payload, {
    headers: await authHeader(),
  });
}
async function updateStream(id, payload) {
  delete payload.id
  return await axios.patch(streamURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateStream(id, status) {
  return await axios.put(streamURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}
async function deleteStream(id) {
  return await axios.delete(streamURL + '/' + id, {
    headers: await authHeader(),
  });
}
async function streamFileUpload(id, file) {
  return await axios.patch(streamURL + '/image/' + id, { file }, {
    headers: await authHeader('FormData'),
  });
}
/********* End STREAM *********/

/********* CATEGORY *********/
async function getLanguage(limit, offset, status, keyword) {
  return await axios.get(languageURL + '/admin?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}
async function addLanguage(payload) {
  return await axios.post(languageURL, payload, {
    headers: await authHeader(),
  });
}
async function updateLanguage(id, payload) {
  return await axios.patch(languageURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateLanguage(id, status) {
  return await axios.put(languageURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}
/********* End Category *********/

export const service = {
  login,
  getDashboard,

  getastrologer,
  statusUpdateastrologer,

  getSpecialization, addspecialization, updateSpecialization, deleteSpecialization,

  getCategory, addCategory, updateCategory, statusUpdateCategory,

  getsubCategory, addSubCategory, updatesubCategory, statusUpdatesubCategory,

  getproducts, UpdateStatusProducts,

  fetchExam, examAdd, examUpdate, examDelete,

  fetchQuestions, addquestion,

  fetchanswers, addanswer, updateanswerStatusData,




  getStream,
  addStream,
  updateStream,
  statusUpdateStream,
  deleteStream,
  streamFileUpload,


  getLanguage,
  addLanguage,
  updateLanguage,
  statusUpdateLanguage,
}
