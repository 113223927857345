import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice';
import loadingReducer from './loader';
import dashboardReducer from './dashboardSlice';
import astrologerReducer from './astrologerSlice';
import specializationReducer from './specializationSlice';
import categoryReducer from './categorySlice';
import subCategoryReducer from './subCategorySlice';
import productsReducer from './productsSlice';
import productImageReducer from './productImageSlice';
import examReducer from './examSlice';
import questionReducer from './questionSlice';
import answerReducer from './answerSlice';


import streamReducer from './streamSlice';
import languageReducer from './languageSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loadingReducer,
    dashboard: dashboardReducer,
    astrologer: astrologerReducer,
    specialization: specializationReducer,
    category: categoryReducer,
    subcategory: subCategoryReducer,
    products: productsReducer,
    productImage: productImageReducer,
    exam: examReducer,
    question: questionReducer,
    answer: answerReducer,

    stream: streamReducer,
    language: languageReducer,
  },
})

export default store;