export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    menucontent: "Dashboards",
    Items: [
      { path: `/dashboard`, icon: "home", activeTitle: "Dashboard", title: "Dashboard", type: "link" },

    ],
  },
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      { path: `/specialization`, icon: "specialization", activeTitle: "specialization", title: "Specialization", type: "link" },
      { path: `/exams`, icon: "blogs", activeTitle: "exams", title: "Exam", type: "link" },
      { path: `/astrologers`, icon: "specialization", activeTitle: "astrologers", title: "Astrologers", type: "link" },

      { path: `/category`, icon: "blogs", activeTitle: "category", title: "Category", type: "link" },
      { path: `/subcat`, icon: "blogs", activeTitle: "subcat", title: "Sub Category", type: "link" },
      { path: `/products`, icon: "blogs", activeTitle: "products", title: "Products", type: "link" },





      // { path: `/language`, icon: "language", activeTitle:"language", title: "Language", type: "link" },
    ],
  },

];
