import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  astrologerData: [],
  totalastrologer:0,
  isOpenModal: false,
  isStatusOpenModal:false,
  isImageViewOpenModal:false,

}

export const astrologerSlice = createSlice({
  name: "astrologer",
  initialState,
  reducers: {
    setastrologerData(state, { payload }) {
      state.astrologerData = payload.result
      state.totalastrologer = payload.totalCount
    },
    updateastrologerData(state, { payload }) {
      const objIndex = state.astrologerData.findIndex((obj) => obj.id === payload.id);      
      if(objIndex>=0){
        state.astrologerData[objIndex].status= payload.status
      }
    },
    pushastrologerData (state,{payload}){
      state.astrologerData.push(payload)
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isOpenImageViewModal(state, { payload }) {
      state.isImageViewOpenModal = payload
    },
    imageViewToggle(state, { payload }) {
      state.isImageViewOpenModal = !state.isImageViewOpenModal
    }
  }
})

export const { setastrologerData, updateastrologerData,pushastrologerData, isOpenModal, 
  ModalToggle,setFaqsSpecializationData,isOpenStatusModal,statusToggle, isOpenImageViewModal, imageViewToggle} = astrologerSlice.actions;

export default astrologerSlice.reducer;

export function getastrologer(limit, offset, status, keyword) {
  return async function getastrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getastrologer(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setastrologerData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addastrologer(payload) {
  return async function addastrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addastrologer(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushastrologerData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateastrologer(id,name) {
  return async function updateastrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateastrologer(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateastrologerData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateastrologer(payload) {
  return async function statusUpdateastrologerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateastrologer(payload.id,payload.status).then(
        (response) => {
          dispatch(statusToggle())
          dispatch(updateastrologerData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

